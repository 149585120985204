export const FormStatuses = {
  SUCCESS: 'success',
  DANGER: 'danger',
};

export const RECAPTCHA_SITE_KEY = '6Le9HxMUAAAAANyre4c_A3W71ECGM7bR_CafngAn';

export const BOKAMERA_COMPANY_ID = '08B32C4F-9CA9-449B-8C69-85773AC2093C';

export const LANGUAGE = import.meta.env.VITE_LANGUAGE || 'sv';

export const ORIGINS = {
  sv: 'bokamera.se',
  no: 'bookmere.no',
  fi: 'bookmore.fi',
  en: 'bookmore.com',
};

export const ORIGIN = ORIGINS[LANGUAGE];

export const COUNTRY_IDS = {
  sv: 'SE',
  no: 'NO',
  fi: 'FI',
  en: 'EN',
};

export const IMAGE_QUALITY = 90;

export const COUNTRY_ID = COUNTRY_IDS[LANGUAGE];

export const LANGUAGE_STORAGE_KEY = 'bmse-language';
